$(function () {
    'use strict';

    /**
     * This file is used to process static (non-Angular) HTML loaded in mustache templates.  All DOM within an
     * Angular template must be in Angluar controllers, etc.
     */
    /* Menu */
    $(".menu_icon")
        .click(function (e) {
            if (__isMobile) {
                e.preventDefault();
                $("#sidebar-wrapper").slideToggle();
            }
        });

    $(".menu_click")
        .click(function (e) {
            if (__isMobile) {
                $("#sidebar-wrapper").slideToggle();
            }
        });

});